<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>添加会员</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <div class="add">
                <el-form ref="form" :rules="rules" :model="form" label-width="120px">
                    <el-form-item label="角色名称" prop="roleName">
                        <el-input v-model="form.roleName" style="width:400px;"></el-input>
                    </el-form-item>
                    
                    <el-form-item label="角色描述" prop="roleDescribe">
                         <el-input type="text" v-model="form.roleDescribe" style="width:400px;" :rows="7"></el-input>
                    </el-form-item>
                    
                    <el-form-item label="角色权限" prop="nodeString">
                    <el-tree
                        :data="data"
                        show-checkbox
                        node-key="id"
                        @check="handCheck"
                        :props="defaultProps"
                        ref="tree"
                    ></el-tree>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handAdd()">确定添加</el-button>
                        <el-button>取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import _api from '@/api/index'
import apiUrl from '@/api/public'
export default {
    data(){
        return{
            form:{
                roleName:"",
                roleDescribe:"",
            },
            rules:{
                roleName:[
                    { required: true, message: '请输入角色名称', trigger: 'blur' },
                ],
                roleDescribe:[
                    { required: true, message: '请输入角色描述', trigger: 'blur' },
                ],
                nodeString:[
                    { required: true, message: "请选择权限", trigger: "blur" }
                ]
            },
            nodeString: "", //选中上传的权限
            data: [],
            defaultProps: {
                children: "child",
                label: "name"
            },
            nodeList:[]
        }
    },
    created(){
        this.getAllPermission()
    },
    methods:{
        getimgurl(){

        },
        getAllPermission(){
            _api.get(apiUrl.selectAllPermission,{}
            ).then(res=>{
                if (res.code === 200) {
                    this.$message.success('获取角色权限成功')
                    this.data = res.data
                }
            }).catch(err =>{
                console.log(err)
            })
        },
        handCheck(data, checkedNodes) {
            const nodeList = [];
            let tree = this.$refs.tree;
            let node = tree.getCheckedKeys().concat(tree.getHalfCheckedKeys());
            this.nodeList = node
            const nodeString = node.join(",");
            this.nodeString = nodeString;
        },
        handAdd() {
            if(this.nodeList.length==0){
                this.$message({
                message: '最少选择一个权限',
                type: 'warning'
                });
                return false
            }else{
            var data = {
                roleName:this.form.roleName,
                remake:this.form.roleDescribe,
                permissionId:this.nodeList
            };
            _api.post(apiUrl.addRole,data
                ).then(res=>{
                    if (res.code === 200) {
                        this.$message.success('添加角色成功')
                    }
                }).catch(err =>{
                    console.log(err)
                    this.$message.error(err)
                })
            }
        },
        
    }
}
</script>
<style scoped>
.add{ width:900px;}
</style>